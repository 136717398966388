import { Clip } from 'data/Clip'
import { VideoSourceData } from 'data/ReviewSelectionInit'
import { DownloadClips, FunctionTrigger } from 'data/common'
import { User } from 'firebase/auth'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { useProFeaturesStore } from 'hooks/UseProFeaturesStore'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { firebaseConfig } from 'services/FirebaseConfig'
import { getChildCategories } from 'templates/TemplateConfig'
import { ActivityTemplates } from 'templates/TemplateLibrary'
import { HighlightCreator } from 'ui/HighlightCreator'
import { ReviewActivityType } from 'ui/ReviewMetaStore'
import { useUnmount } from 'usehooks-ts'
import crownIcon from '../icons/crown_icon.svg'
import { HighlightProps } from './ShareReviewDialog'
import { FirebaseDb, FirestoreDb, getApp } from './common/Firebase'
import { RoundButton } from './common/RoundButton'

export const HighlightsDownloadBox: React.FC<{
  reviewId: string
  activityType: ReviewActivityType
  firebaseDb: FirebaseDb
  videoSourceData: VideoSourceData
  highlightProps: HighlightProps | undefined
  highlightCreator: HighlightCreator | undefined
  fireStore: FirestoreDb
  user: User | undefined
}> = ({ videoSourceData, highlightCreator, highlightProps, ...props }) => {
  const [jobs, setJobs] = useState<HighlightDownloadHandler[]>([])

  const [jobsProgress, setJobsProgress] = useState<
    Map<HighlightDownloadHandler, DownloadStatus['step']>
  >(new Map())

  useEffect(() => {
    const unsubscribes = jobs.map((job) =>
      job.setStatusChangeListener((status) =>
        setJobsProgress((it) => it.set(job, status.step).entriesArray().toMap()),
      ),
    )
    return () => unsubscribes.forEach((it) => it())
  }, [jobs])

  const inProgress = useMemo(
    () => jobs.map((job) => jobsProgress.get(job)).some((it) => it !== 'completed'),
    [jobs, jobsProgress],
  )

  useUnmount(() => {
    jobs.forEach((job) => job.dispose())
  })

  const {
    featureFlags: { exportVideoEnabled },
  } = useProFeaturesStore({
    user: props.user,
    reviewId: props.reviewId,
  })

  const handleProcessClick = useCallback(async () => {
    if (!exportVideoEnabled) {
      toast('You need to be a PRO user to export a highlights video', {
        type: 'info',
        position: 'bottom-center',
      })
      return
    }

    if (inProgress) {
      console.log('Export video already in progress')
      return
    }

    const clips =
      highlightProps ?
        highlightCreator?.createHighlightClips({
          ...highlightProps,
          eventFilters: highlightProps?.eventFilters?.flatMap((filter) =>
            getChildCategories(ActivityTemplates[props.activityType], filter),
          ),
        })
      : undefined

    if (!clips?.length) {
      console.error('No highlights found')
      return
    }

    const highlightDownloadHandler = await triggerVideoExport(
      props.firebaseDb,
      videoSourceData,
      clips,
    )

    if (highlightDownloadHandler) {
      setJobs((it) => [...it, highlightDownloadHandler])
    }
  }, [
    exportVideoEnabled,
    inProgress,
    highlightProps,
    highlightCreator,
    props.firebaseDb,
    props.activityType,
    videoSourceData,
  ])

  return (
    <div className='flex flex-col'>
      <RoundButton
        alt='Export Highlights Video'
        className='w-fit'
        icon={crownIcon}
        onClick={handleProcessClick}
        aria-disabled={inProgress}>
        Export highlights video (Experimental)
      </RoundButton>
      {jobs.map((job, index) => (
        <HighlightsDownloadProgress
          key={index}
          downloadHandler={job}
        />
      ))}
    </div>
  )
}
export const HighlightsDownloadProgress: React.FC<{
  downloadHandler: HighlightDownloadHandler
}> = ({ downloadHandler }) => {
  const [status, setStatus] = useState<DownloadStatus>({ step: 'starting' })
  const clips = downloadHandler.clips
  useEffect(() => {
    const unsubscribe = downloadHandler.setStatusChangeListener(setStatus)
    return unsubscribe
  }, [downloadHandler])

  const expectedDuration = useMemo(() => {
    return clips?.map((clip) => clip.end - clip.start).sum()
  }, [clips])

  return (
    <div className='flex w-full flex-col gap-2 p-2 font-mono text-sm'>
      <span>
        {status.functionTrigger &&
          !!status.functionTrigger.progress &&
          `${status.functionTrigger.progress - (status.functionTrigger.errors?.length ?? 0)}/${clips.length} clips processed`}
      </span>
      <span>
        {status.functionTrigger &&
          status.functionTrigger.errors &&
          `${status.functionTrigger.errors.length}/${clips.length} clips failed to process`}
      </span>
      {status.step === 'completed' && status.functionTrigger.result?.type === 'SUCCESS' && (
        <a
          className='rounded-full bg-blue-grey px-2 py-1 text-white'
          href={status.functionTrigger.result?.payload.downloadURL}
          download={'highlights.mp4'}>
          Download
        </a>
      )}
      <span className=''>
        {(status.step === 'clipping' || status.step === 'downloading') &&
          expectedDuration &&
          status.functionTrigger.totalSteps && (
            <LoadingProgressContainer
              expectedLoadingTimeSeconds={Math.max(expectedDuration, 120)}
              realProgress={
                (status.functionTrigger?.progress ?? 0) / status.functionTrigger.totalSteps
              }>
              {(progress) => `${Math.floor(progress)}% `}
            </LoadingProgressContainer>
          )}
        {status.step === 'compositing' && expectedDuration && (
          <LoadingProgressContainer
            expectedLoadingTimeSeconds={expectedDuration / 20}
            realProgress={0}>
            {(progress) => `${Math.floor(progress)}% `}
          </LoadingProgressContainer>
        )}

        {status.step === 'downloading' ?
          'Downloading...'
        : status.step === 'compositing' ?
          'Combining clips...'
        : status.step === 'clipping' || status.step === 'starting' ?
          'Generating clips...'
        : status.step === 'completed' ?
          'Done'
        : undefined}
      </span>
    </div>
  )
}

async function triggerXHRDownload(url: string, filename?: string) {
  return await new Promise<void>((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.responseType = 'blob'
    xhr.onload = async (event) => {
      if (xhr.status === 200) {
        const blob = xhr.response
        const contentDisposition = xhr.getResponseHeader('Content-Disposition')
        if (!filename && contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          const matches = filenameRegex.exec(contentDisposition)
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '')
          }
        }

        filename = filename || 'highlights.mp4'

        downloadFile(blob, filename)
        // Use the Web Share API if available
        if (navigator.canShare && navigator.canShare({ files: [new File([blob], filename)] })) {
          try {
            await navigator.share({
              files: [new File([blob], filename)],
              title: 'Highlights Video',
              text: 'Check out this highlights video!',
            })
            console.log('Successfully shared the video')
          } catch (error) {
            console.error('Error sharing the video:', error)
          }
        }
      } else {
        console.error('Failed to download file:', xhr.statusText)
      }
      resolve()
    }
    xhr.onerror = () => {
      console.error('XHR error occurred')
      reject(new Error('XHR error occurred'))
    }
    xhr.open('GET', url)
    xhr.send()
  })
}

function downloadFile(blob: Blob, filename: string) {
  const url = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  link.download = filename

  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  window.URL.revokeObjectURL(url)
}

interface LoadingProgressProps {
  realProgress?: number // Real progress value (0 to 100)
  expectedLoadingTimeSeconds: number

  children: (progress: number) => React.ReactNode
}

const LoadingProgressContainer: React.FC<LoadingProgressProps> = ({
  realProgress,
  expectedLoadingTimeSeconds,
  children,
}) => {
  const [displayProgress, setDisplayProgress] = useState(0)
  const startTime = useMemo(() => Date.now(), [])

  useEffect(() => {
    let animationFrameId: number

    const updateProgress = () => {
      const elapsedTime = Date.now() - startTime
      const progressFraction = elapsedTime / (expectedLoadingTimeSeconds * 1000)

      // Calculate fake progress with an exponential decay function
      const fakeValue = 100 * (1 - Math.exp(-3 * progressFraction))

      // Incorporate real progress if it's faster
      const combinedProgress = Math.max(fakeValue, realProgress ?? 0)

      setDisplayProgress(combinedProgress)

      if (combinedProgress < 100) {
        animationFrameId = requestAnimationFrame(updateProgress)
      }
    }

    animationFrameId = requestAnimationFrame(updateProgress)

    return () => {
      cancelAnimationFrame(animationFrameId)
    }
  }, [expectedLoadingTimeSeconds, realProgress, startTime])

  return <>{children(displayProgress)}</>
}

export default LoadingProgressContainer

async function triggerVideoExport(
  firebase: FirebaseDb,
  videoSourceData: VideoSourceData,
  clips: Clip[],
) {
  const youtubeUrl =
    videoSourceData.source === 'Youtube_url' ? videoSourceData.id
    : videoSourceData.source === 'Youtube' ? `https://www.youtube.com/watch?v=${videoSourceData.id}`
    : null

  if (!youtubeUrl) {
    console.error('Unsupported Video Source')
    return
  }
  try {
    const request: DownloadClips.DownloadClipsRequest = {
      sources: [
        {
          url: youtubeUrl,
          clips: clips.map((clip) => ({
            startSeconds: Math.floor(clip.start * 10) / 10,
            endSeconds: Math.ceil(clip.end * 10) / 10,
          })),
        },
      ],
    }
    const functions = getFunctions(getApp(firebaseConfig))
    const response = await httpsCallable<
      DownloadClips.DownloadClipsRequest,
      DownloadClips.DownloadClipsAsyncResponse
    >(functions, 'downloadClipsAsync', { timeout: 540000 })(request)

    console.log('function trigger ref', response.data.databaseRef)
    return new HighlightDownloadHandler(firebase, clips, response.data.databaseRef)
  } catch (error) {
    console.error('Error processing videos:', error)
    return undefined
  }
}

type DownloadStatus =
  | {
      functionTrigger?: Extract<FunctionTrigger, { type: 'DownloadClips' }>
      step: 'starting'
    }
  | {
      functionTrigger: Extract<FunctionTrigger, { type: 'DownloadClips' }>
      step: 'clipping' | 'compositing' | 'downloading' | 'completed'
    }
class HighlightDownloadHandler {
  clips: Clip[]
  private listeners: ((status: DownloadStatus) => void)[] = []
  private renderStarting = true
  private isDownloadAttempted = false
  private isDownloading = false
  functionTrigger?: Extract<FunctionTrigger, { type: 'DownloadClips' }>
  dispose: () => void

  constructor(firebase: FirebaseDb, clips: Clip[], functionTriggerPath: string) {
    this.clips = clips
    this.dispose = firebase
      .getRef<Extract<FunctionTrigger, { type: 'DownloadClips' }>>(functionTriggerPath)
      .onValue(
        (snapshot) => {
          const functionTrigger = snapshot.val() as Extract<
            FunctionTrigger,
            { type: 'DownloadClips' }
          > | null
          if (functionTrigger) {
            this.onFunctionTriggerUpdate(functionTrigger)
          }
        },
        console.error,
        {},
      )
  }

  setStatusChangeListener(listener: (status: DownloadStatus) => void) {
    this.listeners.push(listener)

    return () => {
      this.listeners = this.listeners.filter((it) => it !== listener)
    }
  }

  private refreshStatus() {
    const functionTrigger = this.functionTrigger
    const inProgress =
      (functionTrigger && !functionTrigger.result && !!functionTrigger.totalSteps) ||
      this.renderStarting ||
      this.isDownloading

    const isCompositing = !!(
      functionTrigger &&
      !functionTrigger.result &&
      functionTrigger.totalSteps &&
      functionTrigger.totalSteps === functionTrigger?.progress
    )
    if (functionTrigger) {
      this.listeners.forEach((listener) =>
        listener({
          functionTrigger,
          step:
            this.isDownloading ? 'downloading'
            : isCompositing ? 'compositing'
            : this.renderStarting ? 'starting'
            : inProgress ? 'clipping'
            : 'completed',
        }),
      )
    } else {
      this.listeners.forEach((listener) =>
        listener({
          functionTrigger,
          step: 'starting',
        }),
      )
    }
  }

  private onFunctionTriggerUpdate(
    functionTrigger: Extract<FunctionTrigger, { type: 'DownloadClips' }>,
  ) {
    this.functionTrigger = functionTrigger
    if (functionTrigger.totalSteps) {
      this.renderStarting = false
    }
    if (functionTrigger?.result?.type === 'ERROR') {
      console.error('Error processing videos:', functionTrigger.result)
      toast('Error processing videos', {
        type: 'error',
        position: 'bottom-center',
      })
      this.isDownloading = false
      return this.refreshStatus()
    }

    const successResult =
      functionTrigger?.result?.type === 'SUCCESS' ? functionTrigger.result : undefined

    if (successResult && !this.isDownloadAttempted) {
      this.isDownloadAttempted = true
      this.isDownloading = true
      triggerXHRDownload(successResult.payload.downloadURL).finally(() => {
        this.isDownloading = false

        this.refreshStatus()
      })
    }
    this.refreshStatus()
  }
}
