import { mapToLeaderboardEntryResults } from 'components/RankingsByDiv'
import { useQueryString } from 'components/common/utils/QueryString'
import { randomUUID } from 'components/common/utils/randomUUID'
import { usePromiseState } from 'components/common/utils/reactUtils'
import { Player } from 'data/Player'
import {
  FirebaseGroupDetails,
  FirebaseGroupEntry,
  FirebaseGroupMemberDetails,
  FirebaseGroupPlayerProfileEntry,
  FirebaseGroupTeamProfileEntry,
  FirebaseTeamInviteEntry,
  MetaStatsCache,
  RecentsFirebaseEntry,
  ReviewFirebaseEntry,
} from 'data/common'
import { stringAsDatabaseKey } from 'data/common'
import { DocumentPermissions } from 'data/common'
import { FirebaseGroupLeaderboardEntry } from 'data/leaderboardtypes'
import { User } from 'firebase/auth'
import { limitToFirst } from 'firebase/database'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  FirebaseDb,
  useDatabaseNullableRefLiveValue,
  useDatabasePathLiveValue,
  useDatabaseRef,
  useDatabaseRefLiveValue,
  useMappedDatabaseRefLiveValue,
} from './components/common/Firebase'
import { createRecentReviewEntry } from './data/ReviewSelectionInit'
import { mapReviewVideoDataSourceFromFirebase } from './data/UseReviewSelectionState'
import { initializeMetaStats } from './ui/ReviewMetaStore'

export type GroupSelectionStore = {
  groupIds: string[]
  selectedGroupId: string | undefined
  toggle: (groupId: string | undefined) => void
  addReview: (
    reviewId: string,
    groupId: string,
    documentPermissions: DocumentPermissions,
  ) => Promise<void>
  createNewGroup: (
    group: FirebaseGroupDetails,
  ) => Promise<{ groupId: string; groupDetails: FirebaseGroupEntry } | undefined>
}

export interface GroupPlayerStore {
  groupPlayers: FirebaseGroupPlayerProfileEntry[] | undefined | null
  createGroupPlayer: (name?: string) => Promise<FirebaseGroupPlayerProfileEntry>
  updateGroupPlayerName: (
    groupPlayer: FirebaseGroupPlayerProfileEntry,
    nameChange: string,
  ) => Promise<void>
  updateGroupPlayer: (groupPlayer: FirebaseGroupPlayerProfileEntry) => Promise<void>
}
export interface GroupTeamStore {
  groupTeams: FirebaseGroupTeamProfileEntry[]

  updateGroupTeamEmail: (
    groupTeam: FirebaseGroupTeamProfileEntry,
    emailChange: string,
  ) => Promise<void>
}
export function useGroupSelectionStore({
  firebaseDb,
  user,
  defaultSelection = { type: 'query' },
}: {
  firebaseDb: FirebaseDb
  user: User | undefined
  defaultSelection?: { type: 'query' } | { type: 'option'; groupId?: string }
}): GroupSelectionStore {
  const [queryGroupId, setQueryGroupId] = useQueryString('group')

  const [storedSelected, setStoredSelected] = useState<string | undefined>(
    defaultSelection?.type === 'option' ? defaultSelection.groupId : undefined,
  )

  const setSelected = useCallback(
    (groupId: string | undefined) => {
      if (defaultSelection?.type === 'query') {
        setQueryGroupId(groupId)
      }

      if (defaultSelection?.type === 'option') {
        setStoredSelected(groupId)
      }
    },
    [defaultSelection?.type, setQueryGroupId],
  )

  const selected = defaultSelection?.type !== 'option' ? queryGroupId : storedSelected

  const teamInviteRef = useDatabaseRef(firebaseDb, 'team_invite')
  const groupIdsSet = useDatabasePathLiveValue<{ [groupId: string]: number }>(
    firebaseDb,
    user?.uid && `users/${user?.uid}/groups`,
  )

  const createNewGroup = useCallback(
    async (group: FirebaseGroupDetails) => {
      if (user && teamInviteRef) {
        const inviteId = teamInviteRef.push().key!
        const groupBody = {
          details: group,
          owner: user.uid,
          inviteId,
          editingUsers: { [user.uid]: true },
          members: {
            [user.uid]: {
              id: user.uid,
              displayName: user.displayName,
              email: user.email,
              index: 0,
              owner: true,
            },
          },
          reviews: null,
        } satisfies FirebaseGroupEntry
        const groupId = (await firebaseDb.getRef(`groups`).push(groupBody)).key!
        await firebaseDb.getRef(`users/${user.uid}/groups`).update({ [groupId]: true })
        await teamInviteRef.update({
          [inviteId]: {
            details: group,
            groupId,
          } satisfies FirebaseTeamInviteEntry,
        })
        return { groupId, groupDetails: groupBody }
      }
    },
    [firebaseDb, teamInviteRef, user],
  )

  return useMemo(
    () => ({
      groupIds: Object.keys(groupIdsSet ?? {}),
      selectedGroupId: selected,
      toggle: (groupId: string | undefined) => {
        setSelected(groupId === selected ? undefined : groupId)
      },
      addReview: async (reviewId, groupId, documentPermissions) => {
        const reviewRef = firebaseDb.getRef<ReviewFirebaseEntry>(`reviews/${reviewId}`)
        const review = await reviewRef.getVal<ReviewFirebaseEntry>()
        if (!review || !user || documentPermissions !== 'edit') return

        await reviewRef
          .childFromKey('editingUsers')
          .childFromKey(user.uid)
          .runTransaction(() => true, { applyLocally: false })

        reviewRef.child(`groups/${groupId}`).set(true)

        const recentEntry = await createRecentReviewEntry({
          reviewId,
          title: review.title,
          createdTime: review.createdTime,
          firebaseDb,
          reviewOwner: review.owner,
          documentPermissions,
          videoSourceData: mapReviewVideoDataSourceFromFirebase(review.videoId, review.source),
        })

        await firebaseDb.getRef(`groups/${groupId}/reviews/${reviewId}`).set({
          ...recentEntry,
          metaStatsUserCache: {
            [user.uid]: await initializeMetaStats(reviewId, firebaseDb),
          },
        } satisfies RecentsFirebaseEntry & MetaStatsCache)
      },
      createNewGroup,
    }),
    [groupIdsSet, selected, createNewGroup, setSelected, firebaseDb, user],
  )
}

export function useGroupTeamsStore(firebaseDb: FirebaseDb, groupId: string): GroupTeamStore {
  const groupRef =
    useMemo(
      () => groupId && firebaseDb.getRef<FirebaseGroupEntry>(`groups/${groupId}`),
      [firebaseDb, groupId],
    ) || undefined

  const groupTeamsRef = useMemo(() => groupRef?.childFromKey('teams'), [groupRef])

  const groupTeamDictionary = useDatabaseRefLiveValue<FirebaseGroupEntry['teams']>({
    ref: groupTeamsRef,
  })
  const updateGroupTeamEmail = useCallback(
    async (groupTeam: FirebaseGroupTeamProfileEntry, email: string) => {
      if (!groupTeamsRef) {
        process.env.NODE_ENV === 'development' && console.error('groupTeamRef is undefined')
        return
      }

      if (email)
        await groupTeamsRef
          .child(groupTeam.id)
          .child('contacts')
          .child('0')
          .set({ type: 'Email', email })
      else await groupTeamsRef.child(groupTeam.id).child('contacts').child('0').remove()
    },
    [groupTeamsRef],
  )

  const groupTeams = useMemo(() => Object.values(groupTeamDictionary || {}), [groupTeamDictionary])
  return useMemo(
    () => ({
      groupTeams,
      updateGroupTeamEmail,
    }),
    [groupTeams, updateGroupTeamEmail],
  )
}
export type SingleGroupPlayerStore = {
  groupPlayer: FirebaseGroupPlayerProfileEntry | undefined
  updateGroupPlayerName: (
    nameChange: string,
  ) => Promise<FirebaseGroupPlayerProfileEntry | undefined>
  updateGroupPlayerAvatar: (url: string) => Promise<void>
}

export function useSingleGroupPlayerStore(
  firebaseDb: FirebaseDb,
  groupId: string,
  playerName: string,
): SingleGroupPlayerStore {
  const groupRef =
    useMemo(
      () => groupId && firebaseDb.getRef<FirebaseGroupEntry>(`groups/${groupId}`),
      [firebaseDb, groupId],
    ) || undefined

  const groupPlayersRef = useMemo(() => groupRef?.childFromKey('players'), [groupRef])
  const groupPlayerRef = useMemo(
    () => groupPlayersRef?.childFromKey(stringAsDatabaseKey(playerName)),
    [groupPlayersRef, playerName],
  )

  const groupPlayer = useDatabaseRefLiveValue({ ref: groupPlayerRef }) ?? undefined

  const updateGroupPlayerName = useCallback(
    async (nameChange: string) => {
      if (!groupPlayer) {
        process.env.NODE_ENV === 'development' && console.error('groupPlayer is undefined')
        return
      }

      const updatedGroupPlayer: FirebaseGroupPlayerProfileEntry = {
        ...groupPlayer,
        id: stringAsDatabaseKey(nameChange),
        index: groupPlayer?.index,
        name: nameChange,
      }
      await groupPlayersRef?.update({
        [groupPlayer.id]: null,
        [updatedGroupPlayer.id]: updatedGroupPlayer,
      })
      return updatedGroupPlayer
    },
    [groupPlayersRef, groupPlayer],
  )

  const updateGroupPlayerAvatar = useCallback(
    async (url: string) => {
      if (!groupPlayer) {
        process.env.NODE_ENV === 'development' && console.error('groupPlayer is undefined')
        return
      }

      return await groupPlayerRef?.childFromKey('avatarUrl').set(url)
    },
    [groupPlayerRef, groupPlayer],
  )

  return useMemo(
    () => ({
      groupPlayer,
      updateGroupPlayerName,
      updateGroupPlayerAvatar,
    }),
    [groupPlayer, updateGroupPlayerName, updateGroupPlayerAvatar],
  )
}

export function useGroupPlayersStore(firebaseDb: FirebaseDb, groupId: string): GroupPlayerStore {
  const groupRef =
    useMemo(
      () => groupId && firebaseDb.getRef<FirebaseGroupEntry>(`groups/${groupId}`),
      [firebaseDb, groupId],
    ) || undefined

  const groupPlayersRef = useMemo(() => groupRef?.childFromKey('players'), [groupRef])

  const groupLeaderboardRef = useMemo(() => groupRef?.childFromKey('leaderboard'), [groupRef])

  const groupLeaderboardExists = usePromiseState(
    () => groupLeaderboardRef?.get().then((it) => it.exists()),
    [groupLeaderboardRef],
  )

  const groupPlayerDictionaryResult = useMappedDatabaseRefLiveValue(
    () => groupRef?.childFromKey('players'),
    [groupRef],
  )

  const groupPlayers = useMemo(() => {
    if (groupPlayerDictionaryResult.type === 'PENDING') return undefined
    if (groupPlayerDictionaryResult.type === 'ERROR') return null
    if (!groupPlayerDictionaryResult.value) return null

    const players = Object.values(groupPlayerDictionaryResult.value)
      .filterNotNull()
      .orderBy((it) => it.index)

    if (!players.length) return null
    return players
  }, [groupPlayerDictionaryResult])

  const createGroupPlayer = useCallback(
    async (name?: string): Promise<Pick<Player, 'id' | 'index' | 'name'>> => {
      const maxIndex = Math.max(
        ...(groupPlayers ?? [])
          .map((i) => i.index)
          .filterNotNull()
          .concat(0),
      )
      const id = randomUUID()
      const newGroupPlayer: Pick<Player, 'id' | 'index' | 'name'> = {
        id,
        name: name || '',
        index: maxIndex + 1,
      }

      if (!groupPlayersRef && process.env.NODE_ENV === 'development') {
        console.error('groupPlayersRef is undefined')
      }
      groupPlayersRef?.child(id).set(newGroupPlayer)
      return newGroupPlayer
    },
    [groupPlayers, groupPlayersRef],
  )
  const updateGroupPlayerName = useCallback(
    async (groupPlayer: Pick<Player, 'id' | 'index' | 'name'>, nameChange: string) => {
      if (!groupPlayersRef && process.env.NODE_ENV === 'development') {
        console.error('groupPlayersRef is undefined')
        return
      }
      const updatedGroupPlayer = {
        id: groupPlayer.id,
        index: groupPlayer.index,
        name: nameChange,
      }
      return await groupPlayersRef?.child(groupPlayer.id)?.set(updatedGroupPlayer)
    },
    [groupPlayersRef],
  )
  const updateGroupPlayer = useCallback(
    async (groupPlayer: FirebaseGroupPlayerProfileEntry) => {
      if (!groupPlayersRef && process.env.NODE_ENV === 'development') {
        console.error('groupPlayersRef is undefined')
        return
      }
      return await groupPlayersRef?.child(groupPlayer.id)?.set(groupPlayer)
    },
    [groupPlayersRef],
  )

  return useMemo(
    () => ({
      groupPlayers,
      createGroupPlayer,
      updateGroupPlayerName,
      updateGroupPlayer,
    }),
    [createGroupPlayer, groupPlayers, updateGroupPlayerName, updateGroupPlayer],
  )
}
export function useGroupDetailsStore(firebaseDb: FirebaseDb, groupId: string | undefined) {
  return useDatabasePathLiveValue<FirebaseGroupEntry['details']>(
    firebaseDb,
    groupId ? `groups/${groupId}/details` : undefined,
  )
}
export type GroupMembersStore = {
  groupMembers:
    | {
        [id: string]: FirebaseGroupMemberDetails
      }
    | null
    | undefined
  removeGroupMember: (memberId: string) => Promise<void>
}
export function useGroupMembersStore(
  firebaseDb: FirebaseDb,
  groupId: string | undefined,
): GroupMembersStore {
  const groupRef =
    useMemo(
      () => groupId && firebaseDb.getRef<FirebaseGroupEntry>(`groups/${groupId}`),
      [firebaseDb, groupId],
    ) || undefined
  const groupMembersRef = useMemo(() => groupRef?.childFromKey('members'), [groupRef])

  const groupMembers = useDatabaseRefLiveValue<FirebaseGroupEntry['members']>({
    ref: groupMembersRef,
  })

  const removeGroupMember = useCallback(
    async (memberId: string) => {
      if (!groupMembersRef) return
      await groupMembersRef.child(memberId).remove()
      await firebaseDb.getRef(`users/${memberId}/groups/${groupId}`).remove()
    },
    [firebaseDb, groupId, groupMembersRef],
  )

  return useMemo(
    () => ({
      groupMembers,
      removeGroupMember,
    }),
    [groupMembers, removeGroupMember],
  )
}
export function useGroupInviteDetailsStore(firebaseDb: FirebaseDb, inviteId: string | undefined) {
  return useDatabasePathLiveValue<FirebaseTeamInviteEntry>(
    firebaseDb,
    inviteId && `team_invite/${inviteId}`,
  )
}

type GroupOtherSettingsStore = {
  otherSettingsDictionary: FirebaseGroupEntry['otherSettings']
  toggleSetting: (key: string) => void
}

export function useGroupOtherSettingsStore({
  firebaseDb,
  groupId,
}: {
  firebaseDb: FirebaseDb
  groupId: string
}): GroupOtherSettingsStore {
  const otherSettingsRef = useDatabaseRef<NonNullable<FirebaseGroupEntry['otherSettings']>>(
    firebaseDb,
    `groups/${groupId}/otherSettings`,
  )
  const otherSettingsDictionary = useMappedDatabaseRefLiveValue(
    () => otherSettingsRef,
    [otherSettingsRef],
  )

  const toggleSetting = useCallback(
    (key: string) => {
      otherSettingsRef && otherSettingsRef.childFromKey(key).runTransaction((it) => !it)
    },
    [otherSettingsRef],
  )

  return useMemo(
    () => ({
      otherSettingsDictionary:
        otherSettingsDictionary.type === 'SUCCESS' ?
          (otherSettingsDictionary.value ?? null)
        : undefined,
      toggleSetting,
    }),
    [otherSettingsDictionary, toggleSetting],
  )
}
export const generatePlayersAndTeamsFromLeaderboard = async (
  firebaseDb: FirebaseDb,
  groupId: string,
) => {
  const groupRef = firebaseDb.getRef(`groups/${groupId}`)
  const groupPlayersRef = groupRef.child<FirebaseGroupEntry['players']>('players')
  const groupTeamsRef = groupRef.child<FirebaseGroupEntry['teams']>('teams')
  const groupLeaderboardRef = groupRef.child<FirebaseGroupEntry['leaderboard']>('leaderboard')
  const groupPlayerDictionary = await groupPlayersRef.getVal()
  const groupTeamDictionary = await groupTeamsRef.getVal()

  const leaderBoardMeasuresResults = mapToLeaderboardEntryResults(
    (await groupLeaderboardRef
      .child<Partial<FirebaseGroupLeaderboardEntry['resultsByPlayer']>>('resultsByPlayer')
      .childFromKey('games_played')
      .getVal()) ?? {},
  )

  const [measureKey, gamesPlayedResults] =
    Object.entries(leaderBoardMeasuresResults)
      .filter(([key, results]) => results)
      .firstOrNull() ?? []

  if (!gamesPlayedResults) {
    console.error('leaderboard results is undefined')
    return
  }

  const existingPlayers = Object.values(groupPlayerDictionary || {}).filterNotNull()
  const existingTeams = Object.values(groupTeamDictionary || {}).filterNotNull()

  const existingPlayerNames = new Set(existingPlayers.map((player) => player.name))
  const existingTeamDivNames = new Map(
    existingTeams.map((team) => [`${team.name}_${team.division?.key}}`, team]),
  )

  const collatedResults = Object.values(gamesPlayedResults).reduce(
    (acc, result) => {
      acc.players.add(result.player)
      result.teamName &&
        acc.teams.set(`${result.teamName}_${result.dividers.division?.key}`, {
          name: result.teamName,
          division:
            result.dividers.division ?
              {
                name: result.dividers.division.title,
                key: result.dividers.division.key,
              }
            : null,
        })
      return acc
    },
    {
      players: new Set<string>(),
      teams: new Map<string, Omit<NonNullable<FirebaseGroupEntry['teams']>[string], 'id'>>(),
    },
  )

  const playersMaxIndex = Math.max(
    ...existingPlayers
      .map((i) => i.index)
      .filterNotNull()
      .concat(0),
  )

  const newPlayers = Array.from(collatedResults.players.difference(existingPlayerNames)).mapNotNull(
    (name, index) => {
      return {
        id: stringAsDatabaseKey(name),
        name,
        index: playersMaxIndex + index + 1,
      } satisfies NonNullable<FirebaseGroupEntry['players']>[string]
    },
  )

  const newTeams = collatedResults.teams
    .entriesArray()
    .filter(([name]) => !existingTeamDivNames.has(name))
    .mapNotNull(([name, team], index) => {
      const key = groupTeamsRef.push().key
      if (!key) {
        console.error('key is undefined')
        return
      }
      return {
        ...team,
        id: key,
      } satisfies NonNullable<FirebaseGroupEntry['teams']>[string]
    })

  newPlayers.forEach((player) => {
    groupPlayersRef.child(player.id).set(player)
  })

  newTeams.forEach((team) => {
    groupTeamsRef.child(team.id).set(team)
  })
}
