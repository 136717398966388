import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  CloseIcon,
  LinkIcon,
} from '@chakra-ui/icons'
import { Flex, usePrevious } from '@chakra-ui/react'
import { Colors } from 'Colors'
import {
  FirebaseBaseLeaderboardEntry,
  FirebasePlayerGameResult,
  LeaderboardEntry,
  NswdlDivFilter,
  ResultDividers,
  RoundInfo,
} from 'data/leaderboardtypes'
import fuzzysearch from 'fuzzysearch'
import { getReviewWatcherStatRecordId } from 'hooks/UseStatRecordReviewId'
import React, {
  Fragment,
  Suspense,
  forwardRef,
  lazy,
  useCallback,
  useDeferredValue,
  useEffect,
  useState,
} from 'react'
import { isMobile } from 'react-device-detect'
import ReactTagManager from 'react-ga4'
import { InView } from 'react-intersection-observer'
import { ThreeDots } from 'react-loading-icons'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { copyToClipboard, getPlaceholderThumbnail } from 'util/links'
import { normaliseName } from '../data/NormaliseName'
import { useRankingsResults } from '../hooks/UseRankingsResults'
import searchIcon from '../icons/magnify_graph.png'
import playIcon from '../icons/play.png'
import { DocumentTitle } from './DocumentTitle'
import { DraggableScrollBox } from './DraggableScrollBox'
import { PageLoader } from './PageLoader'
import { PlayerLeaderboardStatProfile } from './PlayerLeaderboardStatProfile'
import PlayerStatProfileCarousel from './PlayerStatProfileCarousel'
import { Measure, MeasureResult } from './RankingsByDiv'
import { useIsChanging, usePlayerProfileSearchBehaviour } from './RankingsTableFilterStrategy'
import { SearchInput } from './SearchInput'
import { InlinePill, TeamEntry } from './TeamEntry'
import { tryOpenNewTab } from './TryOpenNewTab'
import { FirebaseDb, FirebaseDbReference } from './common/Firebase'
import { RoundButton } from './common/RoundButton'
import { useQueryString } from './common/utils/QueryString'
import { cn } from './common/utils/tailwindUtils'

export const MAX_RANKING = 20

export function RankingsTable({
  firebase,
  results,
  isGroupMember,
  measure,
  round: selectedRound,
  division: selectedDivision,
  referrerUrl,
  scrollingParentRef,
  backgroundColor,
  groupId,
  includeForFun,
  leaderboardRef,
  leaderboardTitle,
  onMeasureSelected,
  onDivisionSelected,
  byTeam,
}: {
  includeForFun?: boolean
  firebase: FirebaseDb
  isGroupMember?: boolean
  measure: Measure
  round: RoundInfo | undefined
  division: NswdlDivFilter
  referrerUrl: string
  results: LeaderboardEntry['resultsByPlayer']
  scrollingParentRef?: React.RefObject<Element>
  backgroundColor: string
  onMeasureSelected: (measure: Measure) => void
  onDivisionSelected: (division: NswdlDivFilter) => void
  byTeam?: boolean
} & (
  | {
      leaderboardRef: FirebaseDbReference<FirebaseBaseLeaderboardEntry>
      groupId: string | undefined
      leaderboardTitle: string | undefined
    }
  | {
      leaderboardRef?: undefined
      groupId?: undefined
      leaderboardTitle?: undefined
    }
)) {
  const [searchPlayerTerm, setSearchPlayerTerm] = useState<string>()
  const [searchTeamsTerm, setSearchTeamsTerm] = useState<string>()

  const {
    rankings,
    rankingsByMeasure,
    filterdCarouselRankingsByMeasure,
    previousRankings,
    top3Record,
    filteredTableRankings,
  } = useRankingsResults(
    results,
    selectedRound,
    selectedDivision,
    measure,
    searchPlayerTerm,
    searchTeamsTerm,
    byTeam,
  )

  const { selectedPlayerProfile, setSelectedPlayerProfile } = usePlayerProfileSearchBehaviour({
    searchPlayerTerm,
    rawRankings: rankings,
  })

  const rowheight = isMobile ? 48 : 60

  return (
    <Flex
      direction={'column'}
      alignItems={'stretch'}
      style={{
        color: 'white',
        fontFamily: 'LeagueSpartan, sans-serif',
        width: '100%',
      }}>
      <InView
        threshold={0}
        rootMargin='-200px'
        root={scrollingParentRef?.current}>
        {({ inView, ref, entry }) => (
          <div
            ref={ref}
            className='flex w-full flex-row justify-center'>
            {entry?.boundingClientRect.top &&
              (entry?.rootBounds?.top ?? 0) > entry?.boundingClientRect.top && (
                <MeasureDividersTitle
                  className='fixed top-10 z-10'
                  measure={measure}
                  selectedDivision={selectedDivision}
                  selectedRound={selectedRound}
                />
              )}
          </div>
        )}
      </InView>
      {groupId && selectedPlayerProfile && (
        <DocumentTitle title={`Player Profile - ${selectedPlayerProfile} | ${leaderboardTitle}`}>
          <PlayerStatProfileCarousel
            MAX_RANKING={MAX_RANKING}
            firebase={firebase}
            groupId={groupId}
            leaderboardTitle={leaderboardTitle}
            selectedDivision={selectedDivision}
            selectedPlayer={selectedPlayerProfile}
            selectedMeasure={measure}
            selectedRound={selectedRound}
            includeForFun={includeForFun}
            rankingsByMeasure={filterdCarouselRankingsByMeasure}
            onClose={() => setSelectedPlayerProfile(undefined)}
            isGroupMember={isGroupMember}
            leaderboardRef={leaderboardRef}
            onMeasureSelected={onMeasureSelected}
            onDivisionSelected={onDivisionSelected}
            onPlayerSelected={(player) => setSelectedPlayerProfile(player)}
          />
        </DocumentTitle>
      )}
      {top3Record && !measure.disableTopGameRecords && !searchPlayerTerm && !searchTeamsTerm && (
        <Flex
          alignItems={'center'}
          direction={'column'}>
          <Flex
            direction={'column'}
            w={'100%'}
            maxW={500}
            gap={rowheight / 6}>
            {top3Record.map((record, index) => (
              <RankRow
                height={rowheight}
                prefix={<div className='w-12' />}
                resultBackgroundColor={backgroundColor}
                key={index}
                playerName={record.item.player}
                rank={record.rank.toString()}
                result={record.item.displayValue ?? record.item.value?.toString() ?? '--'}
                subtitle={index === 0 ? 'Top 1 game records' : undefined}
                resultLabel={index === 0 ? measure.title : undefined}
                expandedContent={
                  <div className='flex flex-col gap-6 pl-8'>
                    <div className='flex w-full flex-row items-center justify-center'>
                      <RoundButton
                        className='w-fit rounded-md bg-slate-200 bg-gradient-to-r from-slate-600 to-slate-800 text-white'
                        onClick={() => {
                          ReactTagManager.event('leaderboard_view_player_profile_clicked', {
                            category: 'leaderboard',
                          })
                          setSelectedPlayerProfile(record.item.player)
                        }}>
                        See Player Profile
                      </RoundButton>
                    </div>
                    <ScrollableReviewResultsList
                      referrerUrl={referrerUrl}
                      measure={measure}
                      firebase={firebase}
                      reviews={[record.item]}
                      enableReviewNavigation={!!isGroupMember}
                    />
                  </div>
                }
              />
            ))}
          </Flex>
          <div style={{ height: 50 }} />
        </Flex>
      )}

      <Flex
        direction={'column'}
        marginBottom={60}
        gap={rowheight / 3}>
        {filteredTableRankings(rankingsByMeasure.get(measure))?.map((ranking, index) => {
          const previousRanking = previousRankings?.find(
            (it) => it.player === ranking.player,
          )?.displayRank
          return (
            <RankRow
              height={rowheight}
              playerLabel='Player'
              subtitle={index === 0 ? 'Rank' : undefined}
              resultLabel={index === 0 ? measure.title : undefined}
              playerName={ranking.player}
              resultBackgroundColor={backgroundColor}
              prefix={
                ((ranking.displayRank <= MAX_RANKING ||
                  (previousRanking && previousRanking <= MAX_RANKING)) && (
                  <RankingChangeIndicator
                    oldRanking={previousRanking ?? undefined}
                    ranking={ranking.displayRank}
                  />
                )) || <div style={{ width: 20, minWidth: 20 }} />
              }
              rank={ranking.displayRank <= MAX_RANKING ? ranking.displayRank.toString() : '--'}
              result={ranking.total?.displayValue ?? ranking.total?.value?.toString() ?? '--'}
              expandedContent={
                <div className='flex flex-col gap-6 pl-8'>
                  <div className='flex w-full flex-row items-center justify-center'>
                    <RoundButton
                      className='w-fit rounded-md bg-slate-200 bg-gradient-to-r from-slate-600 to-slate-800 text-white'
                      onClick={() => setSelectedPlayerProfile(ranking.player)}>
                      See Player Profile
                    </RoundButton>
                  </div>
                  <ScrollableReviewResultsList
                    referrerUrl={referrerUrl}
                    measure={measure}
                    firebase={firebase}
                    reviews={ranking.reviews}
                    enableReviewNavigation={!!isGroupMember}
                  />
                </div>
              }
              key={ranking.player}
            />
          )
        })}
      </Flex>
      <div
        className={
          'pointer-events-none fixed bottom-0 left-0 z-10 flex w-[100svw] flex-row justify-evenly p-2'
        }>
        <div className='pointer-events-auto flex w-full max-w-72 flex-row justify-center'>
          <TogglableSearchInput
            onSubmit={setSearchPlayerTerm}
            buttonLabel='Search players'
            placeholder='Enter a player name...'
          />
        </div>
        <div className='pointer-events-auto flex w-full max-w-72 flex-row justify-center'>
          <TogglableSearchInput
            onSubmit={setSearchTeamsTerm}
            buttonLabel='Search teams'
            placeholder='Enter a team name...'
          />
        </div>
      </div>
    </Flex>
  )
}

function TogglableSearchInput(props: {
  onSubmit: (value: string | undefined) => void
  className?: string
  buttonLabel?: string
  placeholder?: string
}) {
  const [showSearch, setShowSearch] = useState(false)

  return (
    <>
      {!showSearch && (
        <RoundButton
          className={cn('w-fit', props.className)}
          icon={searchIcon}
          alt={''}
          backgroundColor={Colors.color_playback_crimson}
          color={'white'}
          iconInvert={true}
          onClick={() => setShowSearch(true)}>
          {props.buttonLabel ?? 'Filter'}
        </RoundButton>
      )}
      {showSearch && (
        <SearchInput
          autoFocus={true}
          onChange={(e) => props.onSubmit(e.currentTarget.value)}
          placeholder={props.placeholder ?? 'Enter a search term...'}
          submitText={'Filter'}
          onSubmit={(e) => props.onSubmit(e)}
          onClose={() => {
            setShowSearch(false)
            props.onSubmit(undefined)
          }}
        />
      )}
    </>
  )
}
export function MeasureDividersTitle({
  measure,
  selectedDivision,
  selectedRound,
  className,
}: {
  measure: Measure
  selectedDivision: NswdlDivFilter
  selectedRound: RoundInfo | undefined
  className?: string
}) {
  return (
    <div
      className={cn(
        'rounded-full bg-slate-800 bg-opacity-80 px-4 text-2xl shadow-sm font-bold *:text-playback-crimson',
        className,
      )}
      style={{ maskImage: '' }}>
      {measure.title} <span>|</span> {selectedDivision.title} <span>|</span> {selectedRound?.name}
    </div>
  )
}
export function RankingChangeIndicator({
  ranking,
  oldRanking,
}: {
  ranking: number
  oldRanking: number | undefined
}) {
  const upBy = oldRanking && ranking < oldRanking ? oldRanking - ranking : 0
  const downBy = oldRanking && ranking > oldRanking ? ranking - oldRanking : 0
  const marginY = -8
  return (
    (oldRanking &&
      (ranking < oldRanking ?
        <Flex
          direction={'column'}
          className='items-center'>
          {upBy > 2 && (
            <ChevronUpIcon
              color={Colors.color_green}
              width={20}
              height={20}
              marginY={marginY}
            />
          )}
          {upBy > 1 && (
            <ChevronUpIcon
              color={Colors.color_green}
              width={20}
              height={20}
              marginY={marginY}
            />
          )}
          {upBy > 0 && (
            <ChevronUpIcon
              color={Colors.color_green}
              width={20}
              height={20}
              marginY={marginY}
            />
          )}
          {upBy > 3 ? upBy : ''}
        </Flex>
      : ranking > oldRanking ?
        <Flex
          direction={'column'}
          className='items-center'>
          {downBy > 3 ? downBy : ''}
          {downBy > 2 && (
            <ChevronDownIcon
              color={Colors.color_red}
              width={20}
              height={20}
              marginY={marginY}
            />
          )}
          {downBy > 1 && (
            <ChevronDownIcon
              color={Colors.color_red}
              width={20}
              height={20}
              marginY={marginY}
            />
          )}
          {downBy > 0 && (
            <ChevronDownIcon
              color={Colors.color_red}
              width={20}
              height={20}
              marginY={marginY}
            />
          )}
        </Flex>
      : undefined)) || <div style={{ width: 20, minWidth: 20 }} />
  )
}

export function gameResultsFilters(
  roundCategory: string,
  division: string,
  maxRound: number,
): (it: FirebasePlayerGameResult & { dividers: ResultDividers }) => boolean {
  return (it: FirebasePlayerGameResult & { dividers: ResultDividers }) =>
    it.dividers.round.category == roundCategory &&
    !!it.dividers.matchingDivisions?.any((matchedDivision) =>
      typeof matchedDivision === 'string' ?
        matchedDivision === division
      : matchedDivision.key === division,
    ) &&
    it.dividers.round.number <= maxRound
}

export function filterResults(
  results: LeaderboardEntry['resultsByPlayer'],
  predicate: (it: FirebasePlayerGameResult & { dividers: ResultDividers }) => boolean,
): LeaderboardEntry['resultsByPlayer'] {
  return Object.entries(results).reduce((acc, [measureKey, playerResults]) => {
    if (!playerResults) return acc
    const filteredResults = playerResults.filter(predicate)
    return {
      ...acc,
      [measureKey]: filteredResults,
    }
  }, {})
}

export function RankRow({
  prefix,
  playerName,
  expand,
  onClick,
  expandedContent,
  rank,
  result,
  subtitle,
  resultLabel,
  playerLabel,
  resultBackgroundColor,
  height = isMobile ? 48 : 60,
}: {
  rank?: string
  expandedContent?: React.ReactNode
  playerLabel?: React.ReactNode
  playerName: string
  prefix?: React.ReactNode
  onClick?: () => void
  expand?: boolean
  result: string
  subtitle?: string
  resultLabel?: string
  resultBackgroundColor: string
  height?: number
}) {
  const [expanded, setExpanded] = useState(expand)

  useEffect(() => {
    setExpanded(expand)
  }, [expand])

  const handleOnClick = useCallback(() => {
    onClick && onClick()
    setExpanded((it) => !it)
  }, [onClick])

  return (
    <>
      <div className='relative flex w-full flex-row items-end overflow-clip font-montserrat'>
        <div
          className='flex flex-row items-center justify-center'
          style={{ height }}>
          {prefix}
        </div>
        <div className='flex w-full flex-col content-center gap-1'>
          {(subtitle || resultLabel) && (
            <div className='z-[9] flex w-full flex-row items-end justify-stretch'>
              <div
                style={{
                  textAlign: 'start',
                  width: '80px',
                  fontFamily: 'Montserrat, sans-serif',
                  textTransform: 'uppercase',
                  fontVariationSettings: '"wght" 300',
                  whiteSpace: 'nowrap',
                }}>
                {subtitle}
              </div>
              <div
                style={{
                  textAlign: 'start',
                  fontFamily: 'Montserrat, sans-serif',
                  textTransform: 'uppercase',
                  flexGrow: 1,
                  flexShrink: 1,
                  fontVariationSettings: '"wght" 300',
                }}>
                {playerLabel}
              </div>
              <div
                className='w-[80px] pr-[50px] text-end font-montserrat uppercase'
                style={{
                  fontVariationSettings: '"wght" 300',
                }}>
                {resultLabel}
              </div>
            </div>
          )}
          <div
            className='flex w-full flex-row items-center justify-stretch overflow-clip'
            style={{
              height: height,
              background: 'linear-gradient(to right, #97B4F168, 20%, transparent)',
            }}
            onClick={handleOnClick}>
            <div className='flex w-full flex-row items-center justify-stretch overflow-clip'>
              {rank !== undefined ?
                <div
                  style={{
                    fontVariationSettings: '"wght" 700',
                    fontFamily: 'Montserrat, sans-serif',
                    fontSize: (height * 2) / 3,
                    minWidth: (height * 4) / 3,
                    textAlign: 'center',
                  }}>
                  {rank}
                </div>
              : <div style={{ width: 20 }} />}
              <div
                style={{
                  flexGrow: 1,
                  flexShrink: 1,
                  minWidth: 0,
                  whiteSpace: 'nowrap',
                  textAlign: 'start',
                  fontVariationSettings: '"wght" 700',
                  fontFamily: 'Montserrat, sans-serif',
                  textTransform: 'uppercase',
                  fontSize: height / 3,
                  letterSpacing: '-1',
                  flexBasis: 0,
                  maxWidth: '75%',
                }}
                className='flex h-full flex-row items-center'>
                <span className='absolute w-full'>{playerName}</span>
              </div>
              <div
                className='absolute right-0 flex flex-row items-center justify-end'
                style={{
                  height: height,
                  boxShadow: `0 0 20px 30px ${toRgba(resultBackgroundColor, 0.4)}`,
                  background: toRgba(resultBackgroundColor, 0.4),
                }}>
                <div
                  className='basis-auto text-end font-montserrat text-playback-crimson'
                  style={{
                    fontSize: (height * 4) / 10,
                    fontVariationSettings: '"wght" 700',
                    textShadow: `0 0 5px ${toRgba(resultBackgroundColor, 1.0)}`,
                  }}>
                  {result}
                </div>
                <div className='w-[50px] flex-shrink-0 basis-[50px]'>
                  {expanded ?
                    <ChevronDownIcon
                      width={50}
                      height={50}
                    />
                  : <ChevronRightIcon
                      width={50}
                      height={50}
                    />
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {expanded && expandedContent}
    </>
  )
}
export function ScrollableReviewResultsList({
  className,
  ...props
}: Parameters<typeof ReviewResultsList>[0] & { className?: string }) {
  return (
    <DraggableScrollBox
      direction='horizontal'
      className={cn('scrollbar-none', className)}>
      <ReviewResultsList {...props} />
    </DraggableScrollBox>
  )
}

export function ReviewResultsList({
  firebase,
  enableReviewNavigation,
  reviews,
  measure,
  referrerUrl,
}: {
  firebase: FirebaseDb
  enableReviewNavigation: boolean
  reviews: (FirebasePlayerGameResult & { dividers: ResultDividers })[]
  measure: Measure
  referrerUrl: string
}) {
  const navigate = useNavigate()
  const handleReviewClick = useCallback(
    async (reviewId: string) => {
      // Open a new tab immediately on user click
      const newTab = tryOpenNewTab('about:blank', '_blank')

      ReactTagManager.event('leaderboard_watch_player_highlight_clicked', {
        category: 'leaderboard',
        measure: measure.key,
      })
      const watcherRecordId = await getReviewWatcherStatRecordId({
        firebase,
        reviewId,
      })

      // TODO(DAvey): For teams that aren't signed up for stat records. Link this to the normal review with highlights
      const url = `/watch?statrecordid=${watcherRecordId}&highlights=true${measure.highlightEventKeys.map((key) => `&event=${key}`).join('')}&player=${reviews.find((review) => review.reviewId === reviewId)?.playerId}&referrer_url=${encodeURIComponent(referrerUrl)}`

      if (newTab) {
        newTab.location.href = url
      } else {
        const newTab = tryOpenNewTab(url, '_blank')

        if (!newTab) {
          navigate(url)
        } else {
          newTab.focus()
        }
      }
    },
    [measure.key, measure.highlightEventKeys, firebase, reviews, referrerUrl, navigate],
  )

  return (
    <Flex
      style={{
        flexDirection: 'column',
        flexWrap: 'nowrap',
        alignItems: 'flex-start',
        gap: 10,
      }}>
      {reviews
        .orderByDesc((it) => it.dividers.round.number)
        .map((review) => (
          <Fragment key={review.reviewId}>
            <div className='flex w-full flex-row items-center justify-start gap-2'>
              <div className='inline-block'>
                <RoundButton
                  alt='Watch'
                  className='h-[24px] w-fit'
                  color={Colors.color_white}
                  backgroundColor={Colors.color_playback_crimson}
                  icon={playIcon}
                  onClick={() => handleReviewClick(review.reviewId)}>
                  Watch
                </RoundButton>
              </div>
              <div
                className='flex flex-row items-center justify-start gap-1'
                style={{
                  whiteSpace: 'nowrap',
                  userSelect: 'text',
                }}>
                {review.displayBreakdown ?? review.displayValue ?? review.value ?? '--'}
                <TeamEntry
                  canEnableEditing={false}
                  editable={false}
                  teamName={review.teamName ?? `Team ${(review.team ?? 0) + 1}`}
                  className={'inline-flex w-fit'}
                />
                {review.dividers.division ?
                  <InlinePill backgroundColor={Colors.color_green}>
                    {review.dividers.division.title}
                  </InlinePill>
                : undefined}
                :
                {review.dividers.round ?
                  <InlinePill backgroundColor={Colors.color_black}>
                    {review.dividers.round.name}
                  </InlinePill>
                : undefined}
                {review.opponentName && (
                  <TeamEntry
                    canEnableEditing={false}
                    backgroundColor={Colors.color_grey}
                    editable={false}
                    teamName={review.opponentName}
                    className={'inline-flex w-fit'}
                  />
                )}
                {(
                  enableReviewNavigation // assuming isGroupMember is always true for demonstration
                ) ?
                  <a
                    href={`editor?id=${review.reviewId}&mode=edit`}
                    style={{
                      color: '#888888',
                      userSelect: 'text',
                    }}
                    draggable={false}>
                    {review.title}
                  </a>
                : <span
                    style={{
                      color: '#888888',
                      userSelect: 'text',
                    }}>
                    {review.title}
                  </span>
                }
              </div>
            </div>
          </Fragment>
        ))}
    </Flex>
  )
}

export function playerResultsToLeagueResults(
  measure: Measure,
  filteredResults: LeaderboardEntry['resultsByPlayer'],
  player: string,
  reviews: (FirebasePlayerGameResult & { dividers: ResultDividers })[] = filteredResults[
    measure.key
  ]?.filter((it) => normaliseName(it.player) === player) ?? [],
): {
  player: string
  reviews: (FirebasePlayerGameResult & { dividers: ResultDividers })[]
  total: MeasureResult | undefined
} {
  let total: MeasureResult | undefined
  if (measure.playerValueByLeagueResult) {
    total = measure.playerValueByLeagueResult(filteredResults, player)
  } else if (measure.mode === 'sum') {
    total = { value: reviews.mapProp('value').filterNotNull().sum() }
  } else {
    const average = reviews.mapProp('value').filterNotNull().avgOrNull()
    total = { value: average ? Math.round(average * 10) / 10 : undefined }
  }

  return {
    player,
    reviews,
    total,
  }
}
export type RankedPlayerMeasureResults = {
  displayRank: number
  player: string
  reviews: (FirebasePlayerGameResult & { dividers: ResultDividers })[]
  total: MeasureResult | undefined
}
export function calculateRankedResults(
  filteredResults: LeaderboardEntry['resultsByPlayer'],
  measure: Measure,
): RankedPlayerMeasureResults[] {
  const playerResults =
    filteredResults[measure.key]
      ?.groupBy(({ player }) => normaliseName(player))
      ?.entriesArray()
      ?.mapNotNull(([player, reviews]) => {
        return playerResultsToLeagueResults(measure, filteredResults, player, reviews)
      }) ?? []

  return rankBy(
    playerResults.filter((it) => it.player),
    (it) => it.total?.value ?? -1,
  ).map((it) => ({ ...it.item, displayRank: it.rank }))
}
export type RankedItem<T> = {
  rank: number
  item: T
}

export function rankBy<T>(array: T[], by: (it: T) => number): RankedItem<T>[] {
  const grouppedByValue = array.orderByDesc((it) => by(it)).groupByArray((it) => by(it))

  const rankedResults = grouppedByValue.reduce(
    (acc, [score, group], index) => {
      const updatedPlayers = group.map((item) => {
        if (score !== acc.previousScore) {
          acc.previousScore = score ?? null
          acc.curentDisplayRanking = acc.flattenedResults.length + 1
        }
        return {
          item: item,
          hiddenRank: index + 1,
          rank: acc.curentDisplayRanking ?? 1, // Use display rank which handles ties correctly
        }
      })

      acc.flattenedResults.push(...updatedPlayers)
      return acc
    },
    {
      flattenedResults: [] as {
        item: T
        hiddenRank: number
        rank: number
      }[],
      previousScore: null as number | null,
      curentDisplayRanking: null as number | null,
    },
  ).flattenedResults
  return rankedResults
}

// Utility function to convert various CSS color formats to RGBA
const toRgba = (color: string, alpha: number): string => {
  let r = 0,
    g = 0,
    b = 0

  if (color.startsWith('#')) {
    // Handle hex color
    if (color.length === 4) {
      r = parseInt(color[1] + color[1], 16)
      g = parseInt(color[2] + color[2], 16)
      b = parseInt(color[3] + color[3], 16)
    } else if (color.length === 7) {
      r = parseInt(color[1] + color[2], 16)
      g = parseInt(color[3] + color[4], 16)
      b = parseInt(color[5] + color[6], 16)
    }
  } else if (color.startsWith('rgb')) {
    // Handle rgb and rgba colors
    const rgbValues = color.match(/\d+/g)
    if (rgbValues) {
      r = parseInt(rgbValues[0], 10)
      g = parseInt(rgbValues[1], 10)
      b = parseInt(rgbValues[2], 10)
      if (color.startsWith('rgba') && rgbValues.length === 4) {
        alpha = parseFloat(rgbValues[3])
      }
    }
  } else if (color.startsWith('hsl')) {
    // Handle hsl and hsla colors
    const hslValues = color.match(/\d+/g)
    if (hslValues) {
      const h = parseInt(hslValues[0], 10)
      const s = parseInt(hslValues[1], 10) / 100
      const l = parseInt(hslValues[2], 10) / 100

      const a = s * Math.min(l, 1 - l)
      const f = (n: number) => {
        const k = (n + h / 30) % 12
        return l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1)
      }

      r = Math.round(f(0) * 255)
      g = Math.round(f(8) * 255)
      b = Math.round(f(4) * 255)

      if (color.startsWith('hsla') && hslValues.length === 4) {
        alpha = parseFloat(hslValues[3])
      }
    }
  }

  return `rgba(${r}, ${g}, ${b}, ${alpha})`
}
