import { useCallback, useMemo } from 'react'
import { ANY } from 'templates/Definitions'
import {
  CLIP_DEFINITION,
  EventDefinition,
  MATCH_RESET_DEFINITION,
  POINT_DRAW_DEFINITION,
  POINT_LOSE_DEFINITION,
  POINT_WIN_DEFINITION,
  StatCategory,
  UISetup,
  getChildCategories,
  getParentCategories,
  matchChildCategoryKey,
} from 'templates/TemplateConfig'
import { ActivityTemplates } from 'templates/TemplateLibrary'
import { ThrowDimensions, ThrowKey, ThrowTypeKey } from 'templates/dodgeball/DodgeballDefinitions'
import { DodgeballTemplate } from 'templates/dodgeball/DodgeballTemplate'
import { PropType } from '../components/common/utils/typescriptUtils'
import { isNoteDefinition } from '../data/common'
import { TeamStore } from '../hooks/UseTeamRepo'
import { PlayerStore } from '../ui/PlayerStore'
import { ReviewActivityType } from '../ui/ReviewMetaStore'
import { TimelineStore } from '../ui/TimelineStore'
import { Player } from './Player'
import { TimelineEvent } from './TimelineEvent'

import Definitions = DodgeballTemplate.Definitions

export type CommonPrecedingEvents = {
  count: number
  priority: boolean
  precedingEvents?: Map<EventDefinition, number>
}
export type PrecedingEventPatterns = Map<EventDefinition | undefined, CommonPrecedingEvents>

export interface PlayersStatisticsState {
  playersStats: { [id: PropType<Player, 'id'>]: PlayerStats } | undefined
  teamStats: { [team: number]: PlayerStats } | undefined
  clashes: SimplifiedEvent[][]
  rounds: SimplifiedEvent[][]
  scoreAtTime: (time: number) => [win: number, lose: number, draws: number]
  scoreAtEnd: [win: number, lose: number, draws: number]
  matchNumberAtTime: (time: number) => number
}
function findClashContainingEvent<T extends SimplifiedEvent>(
  clashes: T[][],
  id: PropType<T, 'id'>,
): T[] | undefined {
  return clashes.find((c) => c.find((e) => e.id === id))
}

export function splitClashes(
  sortedEvents: SimplifiedEvent[],
  secondsSplit = 3,
): SimplifiedEvent[][] {
  return sortedEvents.reduce<SimplifiedEvent[][]>((clashes, currentEvent) => {
    let previousClash = clashes.lastOrNull()
    if (!previousClash) {
      previousClash = []
      clashes.push(previousClash)
    }
    const previousEvent = previousClash?.lastOrNull()
    if (!previousEvent) {
      previousClash.push(currentEvent)
      return clashes
    }
    if (currentEvent.time - ((previousEvent.duration ?? 0) + previousEvent.time) > secondsSplit) {
      clashes.push([currentEvent])
      return clashes
    }

    previousClash.push(currentEvent)
    return clashes
  }, [])
}

export function splitRounds(sortedEvents: SimplifiedEvent[]): SimplifiedEvent[][] {
  return sortedEvents.reduce<SimplifiedEvent[][]>((rounds, currentEvent) => {
    let previousRound = rounds.lastOrNull()
    if (!previousRound) {
      previousRound = []
      rounds.push(previousRound)
    }
    const previousEvent = previousRound?.lastOrNull()
    if (!previousEvent) {
      previousRound.push(currentEvent)
      return rounds
    }
    if (previousEvent.isRoundEnd) {
      rounds.push([currentEvent])
      return rounds
    }

    previousRound.push(currentEvent)
    return rounds
  }, [])
}

export type PlayerStats = {
  sets_played: number
  sets_won: number
  sets_win_rate: number
}
export type PlayerDodgeballStats = {
  [T in ThrowKey as T]: number
} & {
  [T in ThrowTypeKey as `${T}_hit_rate`]?: number
} & {
  [T in ThrowTypeKey as `${T}_target_rate`]?: number
} & PlayerStats & {
    kills: number
    deaths: number
    assists: number
    kill_death_ratio: number
    got_caught: number
    got_targeted: number
    defence_success: number
    deaths_by_hit: number
    just_died: number
    block_attempts: number
    block_success: number
    dodge_attempts: number
    dodge_success: number
    catch_attempts: number
    catch_success: number
    unclear_defence_attempts: number
    unclear_defence_success: number
    sets_survived: number
    sets_survival_rate?: number
    sets_death_orders?: number
    defence_success_rate?: number
    unclear_defence_success_rate?: number
    block_success_rate?: number
    dodge_success_rate?: number
    catch_success_rate?: number
  }

function calculateDodgeballPlayerOrTeamStats(
  clashes: SimplifiedDodgeballEvent[][],
  rounds: SimplifiedDodgeballEvent[][],
  events: SimplifiedDodgeballEvent[],
  team: number,
  player?: Player,
): PlayerDodgeballStats {
  const roundsPlayed = rounds.filter((r) => {
    return r.any((e) => {
      return e.containsPlayer(team, player)
    })
  })
  const roundsSurvived = roundsPlayed.filter(
    (r) =>
      r.reduce<number>((accum, e) => {
        if (e.containsPlayer(team, player) && e.isDeathEvent) return accum - 1
        if (e.containsPlayer(team, player) && e.isReviveEvent) return accum + 1
        return accum
      }, 1) >= 1,
  )
  const roundsWon = roundsPlayed.filter((r) => {
    return (
      r.any((e) => {
        return e.containsPlayer(team, player)
      }) && r.any((e) => (team === 0 && e.isWin) || (team === 1 && e.isLose))
    )
  })

  const roundDeathOrders = roundsPlayed.map((r) => {
    const deathsInRound = r.filter((e) => e.isDeathEvent && e.containsPlayer(team))
    const playersInRound = r
      .flatMap((e) => e.players || [])
      .distinctBy((p) => p.id)
      .filter((p) => p.team === (player?.team ?? team)).length
    const indexOf = deathsInRound.findIndex((e) => e.containsPlayer(team, player))
    if (indexOf === -1) return playersInRound
    return indexOf + 1
  })

  const medianDeathOrder = roundDeathOrders.medianOrNull()
  const dodgeballStats: Omit<PlayerDodgeballStats, keyof PlayerStats> = {
    ...DodgeballTemplate.Definitions.mapAsObject(
      { dimension: { throw_result: ANY, throw_type: ANY } },
      (key) => {
        return [key, 0]
      },
    ),
    kill_death_ratio: 0,
    got_caught: 0,
    kills: 0,
    assists: 0,
    deaths: 0,
    got_targeted: 0,
    defence_success: 0,
    deaths_by_hit: 0,
    just_died: 0,
    block_attempts: 0,
    block_success: 0,
    dodge_attempts: 0,
    dodge_success: 0,
    unclear_defence_attempts: 0,
    unclear_defence_success: 0,
    catch_attempts: 0,
    sets_survived: roundsSurvived.length,
    catch_success: 0,
    sets_death_orders: medianDeathOrder !== undefined ? Math.floor(medianDeathOrder) : undefined,
  }
  const stats = {
    sets_played: roundsPlayed.length,
    sets_won: roundsWon.length,
    sets_win_rate: Math.floor(
      roundsPlayed.length && (roundsWon.length * 100) / roundsPlayed.length,
    ),
  }
  events
    .filter((e) => e.containsPlayer(team, player))
    .forEach((e) => {
      if (e.isKillEvent) dodgeballStats.kills++
      if (e.isDeathEvent) dodgeballStats.deaths++
      if (e.isDeathByCatch) dodgeballStats.got_caught++
      if (
        e.isSetUp ||
        (e.isAssistableEvent &&
          findClashContainingEvent(clashes, e.id)?.any(
            (subsequent) =>
              subsequent.isKillEvent &&
              ((subsequent.time > e.time && subsequent.time - e.time < 3) ||
                Math.abs(subsequent.time - e.time) < 0.5) &&
              !subsequent.containsPlayer(team, player),
          ))
      )
        dodgeballStats.assists++
      DodgeballTemplate.Definitions.findContains<ThrowDimensions, ThrowKey>({
        key: 'throw',
      })
        .filter((it) =>
          matchChildCategoryKey(DodgeballTemplate.buttonSetup, Definitions.get('throw'), it.key),
        )
        .forEach((it) => {
          if (e[`is${it.key}`]) {
            dodgeballStats[it.key] ?
              (dodgeballStats[it.key] = dodgeballStats[it.key] + 1)
            : (dodgeballStats[it.key] = 1)
          }
        })

      if (e.isGotTargetedEvent || e.isDeathByHit) dodgeballStats.got_targeted++
      if (e.isDeathByHit) dodgeballStats.deaths_by_hit++
      if (e.isDefenceSuccess) dodgeballStats.defence_success++
      if (e.isBlockResultEvent) dodgeballStats.block_attempts++
      if (e.isDodgeResultEvent) dodgeballStats.dodge_attempts++
      if (e.isBlockSuccess) dodgeballStats.block_success++
      if (e.isDodgeSuccess) dodgeballStats.dodge_success++
      if (e.isCatchResultEvent) dodgeballStats.catch_attempts++
      if (e.isCatchSuccess) dodgeballStats.catch_success++
      if (e.isJustDied) dodgeballStats.just_died++
    })
  dodgeballStats.kill_death_ratio =
    Math.round(
      ((dodgeballStats.kills - dodgeballStats.got_caught + dodgeballStats.catch_success) * 100) /
        (dodgeballStats.deaths + 1),
    ) / 100

  DodgeballTemplate.Definitions.findContains<ThrowDimensions, ThrowTypeKey>({
    dimension: { throw_result: null, throw_type: ANY },
  }).forEach((it) => {
    dodgeballStats[`${it.key}_hit_rate`] =
      dodgeballStats[it.key] ?
        Math.floor((dodgeballStats[`${it.key}_hit`] * 100) / dodgeballStats[it.key])
      : undefined
    dodgeballStats[`${it.key}_target_rate`] =
      dodgeballStats[it.key] ?
        Math.floor((dodgeballStats[`${it.key}_ontarget`] * 100) / dodgeballStats[it.key])
      : undefined
  })
  dodgeballStats.block_success_rate =
    dodgeballStats.block_attempts ?
      Math.floor((dodgeballStats.block_success * 100) / dodgeballStats.block_attempts)
    : undefined
  dodgeballStats.dodge_success_rate =
    dodgeballStats.dodge_attempts ?
      Math.floor((dodgeballStats.dodge_success * 100) / dodgeballStats.dodge_attempts)
    : undefined
  dodgeballStats.sets_survival_rate =
    stats.sets_played ?
      Math.floor((dodgeballStats.sets_survived * 100) / stats.sets_played)
    : undefined
  dodgeballStats.catch_success_rate =
    dodgeballStats.catch_attempts ?
      Math.floor((dodgeballStats.catch_success * 100) / dodgeballStats.catch_attempts)
    : undefined
  dodgeballStats.defence_success_rate =
    dodgeballStats.got_targeted ?
      Math.floor(
        ((dodgeballStats.got_targeted - dodgeballStats.deaths_by_hit) * 100) /
          dodgeballStats.got_targeted,
      )
    : undefined

  dodgeballStats.unclear_defence_attempts =
    dodgeballStats.got_targeted -
    dodgeballStats.dodge_attempts -
    dodgeballStats.block_attempts -
    dodgeballStats.catch_attempts
  dodgeballStats.unclear_defence_success =
    dodgeballStats.defence_success -
    dodgeballStats.dodge_success -
    dodgeballStats.block_success -
    dodgeballStats.catch_success

  dodgeballStats.unclear_defence_success_rate =
    dodgeballStats.unclear_defence_attempts ?
      Math.floor(
        (dodgeballStats.unclear_defence_success * 100) / dodgeballStats.unclear_defence_attempts,
      )
    : undefined

  return { ...stats, ...dodgeballStats }
}

export type SimplifiedEvent = {
  id: string
  containsPlayer: (team: number, player?: Player) => boolean
  players?: Player[]
  team: number
  time: number
  definition: EventDefinition
  key: string
  isNote: boolean
  isRoundEnd: boolean
  isWin: boolean
  isLose: boolean
  isDraw: boolean
  roundNumber: number
  matchNumber: number
  currentScore: [win: number, lose: number, draws: number]
} & TimelineEvent<EventDefinition>
export type SimplifiedDodgeballEvent = {
  [T in ThrowKey as `is${ThrowKey}`]: boolean
} & SimplifiedEvent & {
    currentPlayersOut: [number, number]
    isKillEvent: boolean
    isDeathEvent: boolean
    isAssistableEvent: boolean
    isSetUp: boolean
    isJustDied: boolean
    isGotTargetedEvent: boolean
    isDefenceResultEvent: boolean
    isDefenceSuccess: boolean
    isBlockResultEvent: boolean
    isBlockSuccess: boolean
    isDodgeResultEvent: boolean
    isDodgeSuccess: boolean
    isCatchResultEvent: boolean
    isCatchSuccess: boolean
    isDeathByCatch: boolean
    isDeathByHit: boolean
    isReviveEvent: boolean
  }

export function usePlayersStatisticsState(
  activityType: ReviewActivityType | undefined,
  timelineState: TimelineStore,
  teamStore: TeamStore,
  playerState: PlayerStore,
): PlayersStatisticsState {
  const simplifiedEvents = useMemo(() => {
    return simplifyEvents(
      activityType,
      timelineState.sortedEvents.filter(
        (e) => !isNoteDefinition(e.tag.key) || e.tag.key === CLIP_DEFINITION.key,
      ),
      teamStore.mainTeam,
      teamStore.otherTeam,
    )
  }, [timelineState.sortedEvents, activityType, teamStore])

  const clashes = useMemo(() => {
    return splitClashes(simplifiedEvents)
  }, [simplifiedEvents])

  const rounds = useMemo(() => {
    return splitRounds(simplifiedEvents)
  }, [simplifiedEvents])

  const scoreAtTime = useCallback(
    (time: number): [win: number, lose: number, draws: number] => {
      return simplifiedEvents.findLast((event) => event.time <= time)?.currentScore || [0, 0, 0]
    },
    [simplifiedEvents],
  )
  const scoreAtEnd: [number, number, number] = useMemo(
    () => simplifiedEvents.lastOrNull()?.currentScore || [0, 0, 0],
    [simplifiedEvents],
  )
  const matchNumberAtTime = useCallback(
    (time: number): number => {
      return simplifiedEvents.findLast((event) => event.time <= time)?.matchNumber || 0
    },
    [simplifiedEvents],
  )
  const playersStats = useMemo(() => {
    if (activityType === 'dodgeball')
      return playerState.players.reduce<{ [id: string]: PlayerStats }>((dictionary, player) => {
        dictionary[player.id] = calculateDodgeballPlayerOrTeamStats(
          clashes as SimplifiedDodgeballEvent[][],
          rounds as SimplifiedDodgeballEvent[][],
          simplifiedEvents as SimplifiedDodgeballEvent[],
          player.team,
          player,
        )
        return dictionary
      }, {})
    else return undefined
  }, [clashes, playerState.players, simplifiedEvents, rounds, activityType])
  const teamStats = useMemo(() => {
    if (activityType === 'dodgeball')
      return playerState.players
        .map((it) => it.team)
        .distinct()
        .reduce<{ [team: number]: PlayerStats }>((dictionary, team) => {
          dictionary[team] = calculateDodgeballPlayerOrTeamStats(
            clashes as SimplifiedDodgeballEvent[][],
            rounds as SimplifiedDodgeballEvent[][],
            simplifiedEvents as SimplifiedDodgeballEvent[],
            team,
          )
          return dictionary
        }, {})
    else return undefined
  }, [clashes, playerState.players, simplifiedEvents, rounds, activityType])
  return useMemo(
    () => ({
      playersStats,
      teamStats,
      clashes,
      rounds,
      scoreAtTime,
      scoreAtEnd,
      matchNumberAtTime,
    }),
    [playersStats, teamStats, clashes, rounds, scoreAtTime, matchNumberAtTime, scoreAtEnd],
  )
}

export const POINT_DEFINITION = [
  POINT_LOSE_DEFINITION,
  POINT_WIN_DEFINITION,
  POINT_DRAW_DEFINITION,
].map((d) => d.key)

export const ROUND_END = POINT_DEFINITION.concat(MATCH_RESET_DEFINITION.key)

export function findCommonPrecedingEvents(
  uiSetup: UISetup,
  events: TimelineEvent<EventDefinition>[],
  player: Player,
  timeThreshold: number,
  resultingEventPredicate: (event: TimelineEvent<EventDefinition>, player: Player) => boolean,
): PrecedingEventPatterns {
  const playerActionsMap: Map<EventDefinition | undefined, number> = new Map()
  const playerActionsPrecedingEventMap: Map<
    EventDefinition | undefined,
    Map<EventDefinition, number>
  > = new Map()

  events
    .filter((event) => resultingEventPredicate(event, player))
    .forEach((resultEvent) => {
      // Find actions before death for the player
      const actions = events
        .filter(
          (currentEvent) =>
            !resultingEventPredicate(currentEvent, player) &&
            !getChildCategories(uiSetup, 'RoundEnd').find(
              (it) => it.key === currentEvent.definition_key,
            ) &&
            !isNoteDefinition(currentEvent.definition_key) &&
            Math.abs(currentEvent.time - resultEvent.time) <= timeThreshold &&
            currentEvent.who?.any((currentPlayer) => player.id === currentPlayer.id),
        )
        .orderBy((it) => Math.abs(it.time - resultEvent.time))

      const impliedActions =
        // .slice(0, 1)
        actions
          .flatMap((it) => getParentCategories(uiSetup, it.tag, true))
          .mapNotNull((value) => (typeof value !== 'string' ? value : undefined))

      function incrementPrecedingEvent(
        action: EventDefinition | undefined,
        resultEvent: EventDefinition,
      ) {
        let resultEventMap = playerActionsPrecedingEventMap.get(action)
        if (!resultEventMap) {
          resultEventMap = new Map()
          playerActionsPrecedingEventMap.set(action, resultEventMap)
        }

        let resultEventCount = resultEventMap.get(resultEvent)
        if (resultEventCount === undefined) {
          resultEventCount = 0
          resultEventMap.set(resultEvent, 0)
        }

        resultEventMap.set(resultEvent, resultEventCount + 1)
      }

      function incrementAction(action: EventDefinition | undefined) {
        const playerActionsCount = playerActionsMap.get(action)
        if (playerActionsCount !== undefined) {
          playerActionsMap.set(action, playerActionsCount + 1)
        } else {
          playerActionsMap.set(action, 1)
        }
      }

      // Count actions and update the actions map
      if (impliedActions.length > 0) {
        impliedActions.forEach((action) => {
          incrementAction(action)
        })
        actions.forEach((it) => {
          incrementPrecedingEvent(it.tag, resultEvent.tag)
        })
      } else {
        incrementAction(undefined)
        incrementPrecedingEvent(undefined, resultEvent.tag)
      }
    })

  const minActionCount = 1
  return new Map(
    playerActionsMap
      .entriesArray()
      .orderBy(([action, number]) => -number)
      .mapNotNull(([action, actionCount], index, entries) => {
        if (!action) {
          return actionCount >= minActionCount ?
              [
                action,
                {
                  count: actionCount,
                  priority: true,
                  precedingEvents: playerActionsPrecedingEventMap.get(action),
                },
              ]
            : undefined
          // return undefined
        }
        if (
          getChildCategories(uiSetup, action, false).any((child) =>
            entries.any(([entry, entryCount]) => entry === child && entryCount === actionCount),
          ) ||
          actionCount < minActionCount
        ) {
          return undefined
        }
        const hasHigherParents = !getParentCategories(uiSetup, action, false).any((parent) =>
          entries.any(([entry, entryCount]) => entry === parent && entryCount > actionCount),
        )

        return [
          action,
          {
            count: actionCount,
            priority: hasHigherParents,
            precedingEvents: playerActionsPrecedingEventMap.get(action),
          },
        ]
      }),
    // .slice(0, 4),
  )
}

export function simplifyEvents(
  activityType: ReviewActivityType | undefined,
  sortedEvents: TimelineEvent<EventDefinition>[],
  mainTeam: number,
  otherTeam: number,
): SimplifiedEvent[] {
  if (!activityType) return []
  const matchesChildCategory = (key: string, category: StatCategory) => {
    return !!getChildCategories(ActivityTemplates[activityType], category).find(
      (d) => d.key === key,
    )
  }
  let round = 1
  let match = 0
  let playersOut: [number, number] = [0, 0]
  let win = 0
  let lose = 0
  let draws = 0
  return sortedEvents.map<SimplifiedEvent>((i) => {
    if (MATCH_RESET_DEFINITION.key === i.tag.key) {
      win = 0
      lose = 0
      draws = 0
      if (activityType === 'dodgeball') {
        playersOut = [0, 0]
      }
    }
    if (
      (POINT_WIN_DEFINITION.key === i.tag.key && i.team === mainTeam) ||
      (POINT_LOSE_DEFINITION.key === i.tag.key && i.team === otherTeam)
    ) {
      win++
      if (activityType === 'dodgeball') {
        playersOut = [0, 0]
      }
    }
    if (
      (POINT_LOSE_DEFINITION.key === i.tag.key && i.team === mainTeam) ||
      (POINT_WIN_DEFINITION.key === i.tag.key && i.team === otherTeam)
    ) {
      lose++
      if (activityType === 'dodgeball') {
        playersOut = [0, 0]
      }
    }
    if (POINT_DRAW_DEFINITION.key === i.tag.key) {
      draws++
      if (activityType === 'dodgeball') {
        playersOut = [0, 0]
      }
    }
    let dodgeballEventProperties: Omit<SimplifiedDodgeballEvent, keyof SimplifiedEvent> | object =
      {}
    if (activityType === 'dodgeball') {
      const isKillEvent = matchesChildCategory(i.tag.key, 'KillEvent')
      const isDeathEvent = matchesChildCategory(i.tag.key, Definitions.get('died'))
      const isRevive = matchesChildCategory(i.tag.key, Definitions.get('revive'))
      const isDeathByCaught = matchesChildCategory(i.tag.key, Definitions.get('died_got-caught'))
      const isDeathByHit =
        matchesChildCategory(i.tag.key, Definitions.get('died')) &&
        !matchesChildCategory(i.tag.key, Definitions.get('died_foot-foul')) &&
        !matchesChildCategory(i.tag.key, Definitions.get('died_got-caught'))
      if (isRevive) {
        playersOut[0]--
      }
      if (isDeathByCaught) {
        playersOut[1]--
      }
      if (isDeathEvent) {
        playersOut[0]++
      }
      if (isKillEvent) {
        playersOut[1]++
      }

      dodgeballEventProperties = {
        isKillEvent: isKillEvent,
        isDeathEvent: isDeathEvent,
        isReviveEvent: matchesChildCategory(i.tag.key, Definitions.get('revive')),
        isAssistableEvent:
          matchesChildCategory(i.tag.key, Definitions.get('throw')) ||
          matchesChildCategory(i.tag.key, Definitions.get('defend_catch')),
        isSetUp: matchesChildCategory(i.tag.key, Definitions.get('set_up_success')),
        ...DodgeballTemplate.Definitions.mapAsObject<
          ThrowDimensions,
          ThrowKey,
          boolean,
          `is${ThrowKey}`
        >({ key: 'throw' }, (key, it) => {
          return [`is${key}`, matchesChildCategory(i.tag.key, Definitions.get(it.key))]
        }),
        isDefenceResultEvent: matchesChildCategory(i.tag.key, 'DefenseResult'),
        isGotTargetedEvent: matchesChildCategory(i.tag.key, Definitions.get('got_targeted')),
        isDefenceSuccess: matchesChildCategory(i.tag.key, Definitions.get('defend_success')),
        isBlockResultEvent: matchesChildCategory(i.tag.key, 'BlockResult'),
        isBlockSuccess: matchesChildCategory(i.tag.key, Definitions.get('defend_block_success')),
        isJustDied: matchesChildCategory(i.tag.key, Definitions.get('died_noaction')),
        isDodgeResultEvent: matchesChildCategory(i.tag.key, 'DodgeResult'),
        isDodgeSuccess: matchesChildCategory(i.tag.key, Definitions.get('defend_dodge_success')),
        isCatchResultEvent: matchesChildCategory(i.tag.key, 'CatchResult'),
        currentPlayersOut: [...playersOut],
        isCatchSuccess: matchesChildCategory(i.tag.key, Definitions.get('defend_catch_success')),
        isDeathByCatch: isDeathByCaught,
        isDeathByHit: isDeathByHit,
      } satisfies Omit<SimplifiedDodgeballEvent, keyof SimplifiedEvent>
    }
    const event: SimplifiedEvent = {
      ...i,
      containsPlayer: (team: number, player?: Player) => {
        if (!player) {
          if (team) {
            return i.team === team
          }
          return !!i.who?.length
        }
        return !!i.who?.any((p: Player) => p.id === player.id)
      },
      players: i.who,
      time: i.time,
      definition: i.tag,
      key: i.tag.key,
      id: i.id,
      team: i.team,
      isNote: isNoteDefinition(i.tag.key),
      isRoundEnd: ROUND_END.includes(i.tag.key),
      isWin: POINT_WIN_DEFINITION.key === i.tag.key,
      isLose: POINT_LOSE_DEFINITION.key === i.tag.key,
      isDraw: POINT_DRAW_DEFINITION.key === i.tag.key,
      roundNumber: ROUND_END.includes(i.tag.key) ? round++ : round,
      matchNumber: MATCH_RESET_DEFINITION.key === i.tag.key ? ++match : match,
      currentScore: [win, lose, draws],
      ...dodgeballEventProperties,
    }

    return event
  })
}
