import { TimelineStorage } from 'AppDataRepository'
import { FocusSpan, showDialog } from 'components/common/Dialog'
import { FirebaseDb } from 'components/common/Firebase'
import { User } from 'firebase/auth'
import { stat } from 'fs'
import { Dispatch, SetStateAction } from 'react'
import { NavigateOptions } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ReviewActivityType } from 'ui/ReviewMetaStore'
import { showAssignTeamDialog } from 'ui/ShowAssignTeamDialog'
import { createNewReview } from './ReviewSelectionInit'
import { ReviewFirebaseEntry, VideoSource } from './common'

type CopyReviewDialogState = {
  copyComments: boolean
}

export async function showCopyReviewDialog(
  firebaseDb: FirebaseDb,
  user: User,
  reviewId: string,
  navigate: (url: string, opts?: NavigateOptions) => void,
) {
  const reviewRef = firebaseDb.getRef<ReviewFirebaseEntry>(`reviews/${reviewId}`)
  const newReviewEntry = await new Promise(async (resolve) => {
    showDialog<CopyReviewDialogState>(
      {
        title: 'Copy Review',
        children: (Red, state, setState) => (
          <CopyReviewDialogContent
            Red={Red}
            state={state}
            setState={setState}
          />
        ),
        positiveButtonProps: {
          text: 'Copy',
          onClicked: async (state) => {
            const review = await reviewRef.getVal()
            if (!review) {
              toast.error('Error copying review')
              resolve(false)
              return true
            }
            const {
              commentSummary,
              createdTime,
              editingUsers,
              visits,
              metaStats,
              owner,
              groups,
              statRecordAlias,
              ...reviewPropsToCopy
            } = review

            const newReview: Partial<ReviewFirebaseEntry> = {
              ...reviewPropsToCopy,
              events:
                state.copyComments ?
                  review.events
                : Object.values(review.events)
                    .map(TimelineStorage.mapFirebaseToTimelineEvent('local'))
                    .filter((it) => it.specialType !== 'note')
                    .map(TimelineStorage.mapFirebaseFromTimelineEvent)
                    .map((it) => [it.id, it] as const)
                    .toObject(),
            }
            resolve(newReview)
            return true
          },
        },
        negativeButtonProps: {
          text: 'Cancel',
          onClicked: async (state) => {
            resolve(false)
            return true
          },
        },
        onDismiss() {
          resolve(false)
        },
      },
      { copyComments: false },
    )
  })

  if (!newReviewEntry) return

  const newReviewId = await showAssignTeamDialog({
    firebaseDb,
    onTeamAssigned: async (assignedGroupId, groupSelectionStore) => {
      const parentStatRecord = await reviewRef.childFromKey('parentStatRecord').getVal()
      const newReviewId = await createNewReview({
        activityType: (await reviewRef.childFromKey('activityType').getVal()) ?? 'dodgeball',
        firebaseDb,
        statRecordSourceData:
          parentStatRecord ?
            {
              id: parentStatRecord,
              source: 'StatRecord',
            }
          : undefined,
        user,
        videoSourceData: {
          id: (await reviewRef.childFromKey('videoId').getVal()) ?? '',
          source: (await reviewRef.childFromKey('source').getVal()) ?? 'Youtube',
        },
        groupSelectionStore,
        assignedGroupId,
      })

      await firebaseDb.getRef(`reviews/${newReviewId}`).update(newReviewEntry)
      return newReviewId
    },
    navigate,
    addGroupAuth: {
      user,
    },
  })

  showDialog({
    title: 'Review Copied',
    children: 'Your review has been copied. Would you like to open it?',
    positiveButtonProps: {
      text: 'Open',
      onClicked: () => {
        window.location.href = `/editor?id=${newReviewId}&mode=edit`
        return true
      },
    },
    negativeButtonProps: 'Later',
  })
}

const CopyReviewDialogContent = (props: {
  Red: FocusSpan
  state: CopyReviewDialogState
  setState: Dispatch<SetStateAction<CopyReviewDialogState>>
}) => {
  return (
    <div>
      <p>Are you sure you want to copy this review?</p>

      <input
        checked={props.state.copyComments}
        onChange={(e) => props.setState((it) => ({ ...it, copyComments: e.target.checked }))}
        type='checkbox'
      />
      <label>Copy comments</label>
      <br />
      <props.Red>Comments will {props.state.copyComments ? '' : 'NOT '}be copied</props.Red>
    </div>
  )
}
